import { createPopper, Instance as PopperInstance } from '@popperjs/core';

import { User } from "firebase/auth";

import { ComponentEventDataMap, TypedDataComponent } from "@subgraph-io/edge/component";
import { DOM, DOMRootElement } from "@subgraph-io/edge-ui/dom/DOM";

// @ts-ignore
import SiteHeaderTemplate from '../../../../html-templates/header-nav.njk';

type SiteHeaderNavComponentStoredData = {
    user: {
        replace: User | null
    }
}

type SiteHeaderNavComponentEventMap = ComponentEventDataMap & {
    onSignoutChosen: null
}

export class SiteHeaderNavComponent extends TypedDataComponent<SiteHeaderNavComponentStoredData, SiteHeaderNavComponentEventMap> implements DOMRootElement {

    protected root: DOM;

    protected navigationData: any;
    protected userNavigationData: any;
    protected onUserPage: boolean;

    protected desktopLoggedInIconContainer: HTMLElement;
    protected desktopLoggedInIcon: HTMLElement;
    protected desktopLoggedInMenuPopper: PopperInstance | undefined;
    protected desktopLoggedInMenuOverlay: HTMLElement;
    protected desktopLoggedInMenu: HTMLElement;

    constructor(navigationData: any, userNavigationData: any, headerNavRootElement: HTMLHeadElement, onUserPage: boolean = true) {
        super();
        this.root = new DOM(headerNavRootElement);
        this.navigationData = navigationData;
        this.userNavigationData = userNavigationData;
        this.onUserPage = onUserPage;

        // query and cache buttons for mobile and desktop
        this.desktopLoggedInIconContainer = document.getElementById("logged-in-btn") as HTMLElement;
        this.desktopLoggedInIcon = document.getElementById("logged-in-icon-btn") as HTMLElement;
        this.desktopLoggedInMenu = document.getElementById("account-menu-dropdown") as HTMLElement;

        // create click overlay for handling dropdown close
        this.desktopLoggedInMenuOverlay = document.createElement('div');
        this.desktopLoggedInMenuOverlay.style.display = 'none';
        this.desktopLoggedInMenuOverlay.style.background = 'rgba(0, 0, 0, 0)';
        this.desktopLoggedInMenuOverlay.style.zIndex = '2147483001';
        this.desktopLoggedInMenuOverlay.style.position = 'absolute';
        this.desktopLoggedInMenuOverlay.style.top = '0';
        this.desktopLoggedInMenuOverlay.style.right = '0';
        this.desktopLoggedInMenuOverlay.style.bottom = '0';
        this.desktopLoggedInMenuOverlay.style.left = '0';
        this.desktopLoggedInMenuOverlay.addEventListener('click', this.closeMenu.bind(this));

        // add elements to DOM

        if (onUserPage) {
            const bundleEl = document.getElementById("bundler");

            if (bundleEl) {
                bundleEl.appendChild(this.desktopLoggedInMenuOverlay);
            }
            else {
                document.body.appendChild(this.desktopLoggedInMenuOverlay);
            }
        }
        else {
            document.body.appendChild(this.desktopLoggedInMenuOverlay);
        }

        this.registerForEvent('DATA_CHANGE', '', this.refresh, this);
        this.root.registerForEvent('#desktop-logout', 'click', this.onLogoutChosen, this);
        this.root.registerForEvent('#logged-in-icon-btn', 'click', this.onUserMenuButtonClicked, this);
    }

    public getDOMRoot() {
        return this.root.getDOMRoot();
    }

    public refresh() {
        this.root.getDOMRoot().innerHTML = this.render();

        if (this.onUserPage) {
            // @ts-ignore We still depend on site jquery plugins for the flyout menu, so reinitialize after a refresh
            window.mainNavSubmenus();

            this.applyLogoSwap();
        }
        else {
            this.applySuperfishForNonSiteTemplateRendering();

            const scrollTop = $(window).scrollTop();

            if (scrollTop !== undefined && scrollTop > 0) {
                this.applyLogoSwap();
            }
        }
    }

    protected render() {
        let user: (User & { initials?: string}) | null = this.getTypedData('user');

        if (user !== null) {
            const initials = user?.displayName?.split(' ').map((name) => { return name.charAt(0)}).join('');

            user.initials = initials;
        }

        return SiteHeaderTemplate({ navigation: this.navigationData, userNavigation: this.userNavigationData, pageHasLoaded: true, user });
    }

    protected applyLogoSwap() {
        const logoImg = document.querySelector('#logo a img') as HTMLImageElement;
        const logoAnchor = document.querySelector('#logo a') as HTMLAnchorElement;

        const lightLogoUrl = DOM.getAttribute('data-logo-light', logoAnchor) as string;
        const darkLogoUrl = DOM.getAttribute('data-logo-dark', logoAnchor) as string;

        DOM.setAttribute('src', darkLogoUrl, logoImg);
    }

    protected applySuperfishForNonSiteTemplateRendering() {
        // @ts-ignore
        $('#main-navigation #main-menu ul, #main-navigation .main-menu ul, #main-navigation .nav-tools').superfish({
            popUpSelector: 'ul,.mega-menu-content',
            delay: 250,
            speed: 250,
            animation: { opacity: 'show' },
            animationOut: { opacity: 'hide' },
            cssArrows: !1,
            autoArrows: false,
            onShow: function () {
                $(this).find('.perspective-img-1, .perspective-img-2, .perspective-img-3').css('-webkit-transform', 'translateY(20px)').css('opacity', 1);
            },
            onHide: function () {
                $(this).find('.perspective-img-1, .perspective-img-2, .perspective-img-3').css('-webkit-transform', 'translateY(50px)').css('opacity', 0);
            }
        });

        // @ts-ignore
        $('.topbar-menu > ul').superfish({
            popUpSelector: 'ul',
            delay: 100,
            speed: 300,
            animation: {
                opacity: 'show',
                height: 'show'
            },
            animationOut: {
                opacity: 'hide',
                height: 'hide'
            },
            cssArrows: !1,
            autoArrows: false
        });
    }

    protected closeMenu() {
        this.desktopLoggedInMenuPopper?.destroy();
        this.desktopLoggedInMenuPopper = undefined;
        this.desktopLoggedInMenuOverlay.style.display = 'none';
        this.refresh();
    }

    protected onUserMenuButtonClicked() {
        if (this.desktopLoggedInMenuPopper === undefined) {

            const menuBtn = document.getElementById("logged-in-btn") as HTMLElement;
            const menu = document.getElementById("account-menu-dropdown") as HTMLElement;

            menu.style.display = 'block';
            menu.style.zIndex = '2147483001';
            this.desktopLoggedInMenuOverlay.style.display = 'block';
            this.desktopLoggedInMenuPopper = createPopper(menuBtn, menu, {
                placement: 'bottom-end',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [-10, 0],
                        },
                    },
                ],
            });
        }
        else {
            this.closeMenu();
        }
    }

    protected onLogoutChosen() {
        this.triggerEvent('CLASS_EVENT', 'onSignoutChosen', null);
    }

}